import { useFetcher } from "@remix-run/react";
import { useCallback, useEffect, useRef, useState } from "react";

import { ButtonOld } from "~/components/ButtonOld";
import { Input } from "~/components/Input";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { Modal } from "~/components/Modal";
import { PlusIcon } from "~/components/svg/icons";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { analytics } from "~/utils/analytics";

import "./CreateCartModal.css";

export const CreateCartModal = ({
  isOpen = false,
  onClose,
}: {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const fetcher = useFetcher<any>();
  const [isFetcherLoading, isFetcherDone] = useManagedFetcher({
    fetcher,
    toastSuccessMessage: "Affaire créée avec succès",
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const closeFn = useCallback(
    (val: boolean) => {
      setWasSubmitted(false);
      onClose(val);
    },
    [onClose]
  );

  useEffect(() => {
    if (isFetcherDone && wasSubmitted) {
      analytics.event({
        action: "affaire",
        data: {
          event_label: fetcher.data?.newCart?.label,
        },
      });
      closeFn(false);
    }
  }, [isFetcherDone, closeFn, wasSubmitted, fetcher.data?.cart?.label, fetcher]);

  useEffect(() => {
    if (isFetcherLoading) {
      setWasSubmitted(true);
    }
  }, [isFetcherLoading]);

  return (
    <Modal
      title="Ajouter une nouvelle affaire"
      isOpen={isOpen}
      onClose={() => onClose(false)}
      size="md"
      className="CreateCartModal"
      initialFocus={inputRef}
    >
      <fetcher.Form method="post" action="/account/carts" className="CreateCartModal-form">
        <input type="hidden" name="_action" value="createCart" />

        <Input
          type="text"
          name="label"
          id="newCartLabel"
          label="Nom de la nouvelle affaire"
          className="CreateCartModal-form-input"
          ref={inputRef}
          maxLength={50}
        />
        {fetcher.data?.fieldErrors?.label ? fetcher.data?.fieldErrors?.fieldErrors.label : null}

        <ButtonOld
          type="submit"
          label="Créer une nouvelle affaire"
          IconComponent={
            fetcher.state === "submitting" || fetcher.state === "loading" ? (
              <LoadingSpinner />
            ) : (
              <PlusIcon />
            )
          }
        />
      </fetcher.Form>
    </Modal>
  );
};
